<template>
  <el-select
    :key="lang"
    ref="select"
    v-model="bankName"
    v-loadmore:el-select-dropdown__list.bank-select="loadMore"
    popper-class="bank-select"
    class="bank-name-select"
    filterable
    remote
    :placeholder="placeholder"
    :remote-method="remoteMethod"
    :loading="loading"
    clearable
    style="width: 100%"
    @visible-change="onBlur"
    @change="handleSelect"
  >
    <el-select
      slot="prefix"
      v-model="fitType"
      class="prefix-select"
      @change="handleFitTypeChange"
    >
      <el-option label="名称" value="0" />
      <el-option label="联行号" value="1" />
    </el-select>
    <el-option
      v-for="item in bankList"
      :key="item.cnapsCode"
      :label="item.fullName"
      :value="item.fullName + '/' + item.cnapsCode"
    >
      <div>{{ item.fullName }}</div>
      <div style="margin-left: 8px; color: #8492a6; font-size: 13px">
        {{ item.cnapsCode }}
      </div>
    </el-option>
  </el-select>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getBankList } from '@/api/client-supplier/supplier-manage'
  import { debounce } from 'kits'
  export default {
    name: 'BankNameQuery',
    props: {
      defaultBankName: {
        type: String,
        default: '',
      },
      // 使用BankNameQuery组件时，将控制弹框显示隐藏的属性传递过来，重置开户行的fitType
      dialogVisible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        fitType: '0', // 0=名称 1=联行号   默认为0
        placeholder: '支持模糊搜索，多个词语请用空格隔开',
        bankName: '',
        bankList: [],
        loading: false,
        pageLe: 30,
        pageNo: 1,
        total: 0,
      }
    },

    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
      hasMore() {
        return Math.ceil(this.total / this.pageLe) > this.pageNo
      },
    },
    watch: {
      defaultBankName: {
        deep: true,
        immediate: true,
        handler(val) {
          this.initBankNameList(val)
        },
      },
      // 弹框显示时，开户行默认选中“名称”
      dialogVisible: {
        handler(val) {
          if (val) {
            this.fitType = '0'
          }
        },
      },
    },
    methods: {
      // 开户行 名称/联行号 change事件
      handleFitTypeChange(val) {
        this.placeholder =
          val + '' === '0'
            ? '支持模糊搜索，多个词语请用空格隔开'
            : '请输入联行号'
        this.bankName = ''
        this.handleSelect('')
      },
      initBankNameList(val) {
        if (val) {
          getBankList({
            fits: val.split('/')[0] ? [val.split('/')[0]] : [],
            pageLe: this.pageLe,
            pageNo: this.pageNo,
            fitType: this.fitType,
          }).then((res) => {
            this.bankList = res.data.data
          })
        } else {
          this.bankList = []
        }
        this.bankName = val
      },
      handleSelect(val) {
        this.$emit('bank-name-choose', val)
      },
      onBlur(val) {
        if (!val) {
          this.$refs.select.blur()
        }
      },

      loadMore() {
        if (!this.hasMore) return
        this.pageNo++
        this._getOptions(true)
      },

      // remoteMethod(query) {
      //   this.loading = true
      //   this.query = query ? query.split(' ') : []
      //   this._getOptions()
      // },
      remoteMethod: debounce(function (query) {
        if (!query) {
          this.options = []
          return
        }
        this.loading = true
        this.query = query ? query.split(' ') : []
        this._getOptions()
      }, 1800),
      _reset() {
        this.pageNo = 1
        this.total = 0
        this.bankList = []
      },
      _getOptions(loadMore) {
        if (!loadMore) {
          this._reset()
        }
        getBankList({
          pageLe: this.pageLe,
          pageNo: this.pageNo,
          fits: this.query,
          fitType: this.fitType,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.loading = false
            this.bankList = loadMore
              ? this.bankList.concat(res.data.data)
              : res.data.data
            this.total = res.data.total
          } else {
            this.loading = false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .bank-name-select {
    /deep/ .el-input__prefix {
      left: 0;
    }
    /deep/ .el-input__inner {
      padding-left: 95px;
    }
    .prefix-select {
      /deep/ .el-input__inner {
        padding: 0 15px;
      }
    }
  }
  /deep/ .el-input__prefix {
    .prefix-select {
      width: 80px;
    }
  }
  .el-select-dropdown__item .wrapper {
    display: flex;
    span {
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .tl {
    text-align: left;
    padding-right: 5px;
  }
  .tr {
    text-align: right;
    padding-left: 5px;
    color: #909399;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-1 {
    flex: 1;
  }
</style>
<style>
  .el-form-item__content .bank-select .el-select-dropdown {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
  }

  .bank-select .el-select-dropdown__list {
    height: 300px !important;

    overflow: auto;
    overflow-x: hidden;
  }
  .bank-select .el-select-dropdown__list li {
    min-width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
